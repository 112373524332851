<template>
	<main id="edit" class="edit page">
		<template v-if="showContent">
			<template v-if="showCreate">
				<span class="edit__title">
					Inizia a creare
				</span>
				<form class="edit__form form">
					<span class="edit__preview">
						{{ form.name.given.split('')[0] }}{{ form.name.family.split('')[0] }}
					</span>
					<label for="name">
						<small class="form__label">
							Nome
						</small>
						<input type="name" id="name" class="form__input" v-model="form.name.given" />
					</label>
					<label for="email">
						<small class="form__label">
							Cognome
						</small>
						<input type="email" id="email" class="form__input" v-model="form.name.family" />
					</label>
					<label v-if="emails && emails.length" for="email">
						<small class="form__label">
							La tua email
						</small>
						<input type="email" id="email" class="form__input" disabled style="opacity: 60%;"
							   v-model="emails[0].value" />
					</label>
<!--					<label for="tel">-->
<!--						<small class="form__label">-->
<!--							Inserisci il tuo numero di telefono-->
<!--						</small>-->
<!--						<input type="tel" id="tel" class="form__input" v-model="form.phone[0].value" />-->
<!--					</label>-->
					<span class="form__btn btn filled" :class="{ 'enable': (form.name.family && form.name.given) }"
						  @click="addCard">
						Crea la Digital Business Card
					</span>
				</form>
			</template>
			<template v-else>
				<div class="edit__ctas">
					<!-- <span class="edit__ctas__btn btn outline" @click="discardEdit">
						Indietro
					</span> -->
					<span class="edit__ctas__btn btn outline" @click="updateCard">
						Salva
					</span>
				</div>
				<form class="edit__form form">
					<span class="edit__preview">
						{{ form.name.given.split('')[0] }}{{ form.name.family.split('')[0] }}
					</span>
					<label for="name">
						<small class="form__label">
							Nome
						</small>
						<input type="name" id="name" class="form__input" autocapitalize="characters" v-model="form.name.given" />
					</label>
					<label for="email">
						<small class="form__label">
							Cognome
						</small>
						<input type="email" id="email" class="form__input" autocapitalize="characters" v-model="form.name.family" />
					</label>
					<div class="edit__multi emails">
						<div class="edit__multi__list">
							<label for="email">
								<small class="form__label">
									Email
								</small>
								<template v-if="emails && emails.length">
									<CustomInput v-for="(el, elI) in emails" :key="elI" :index="elI" :info="el" inputType="email" />
									<small v-if="emailError" class="form__error">
										Per favore, inserisci un'email valida
									</small>
								</template>
							</label>
						</div>
						<span class="edit__multi__add" @click="addEmail">
							<span class="edit__multi__add__icon"></span>
							<span class="edit__multi__add__label">
								Aggiungi Email
							</span>
						</span>
					</div>
					<div class="edit__multi phones">
						<div class="edit__multi__list">
							<label for="phone">
								<small class="form__label">
									Telefono
								</small>
								<template v-if="phones && phones.length">
									<CustomPhone v-for="(el, elI) in phones" :key="elI" :index="elI" :info="el" inputType="phone" />
									<small v-if="phoneError" class="form__error">
										Per favore, inserisci un numero di telefono
									</small>
								</template>
							</label>
						</div>
						<span class="edit__multi__add" @click="addPhone">
							<span class="edit__multi__add__icon"></span>
							<span class="edit__multi__add__label">
								Aggiungi Telefono
							</span>
						</span>
					</div>
					<label for="org">
						<small class="form__label">
							Azienda
						</small>
						<input type="org" id="org" class="form__input" autocapitalize="characters" v-model="form.org" />
					</label>
					<label for="title">
						<small class="form__label">
							Titolo
						</small>
						<input type="title" id="title" class="form__input" autocapitalize="characters" v-model="form.title" />
					</label>
					<label for="role">
						<small class="form__label">
							Ruolo
						</small>
						<input type="role" id="role" class="form__input" autocapitalize="characters" v-model="form.role" />
					</label>
					<div class="edit__multi urls">
						<div class="edit__multi__list">
							<label for="url">
								<small class="form__label">
									Url
								</small>
								<template v-if="urls && urls.length">
									<CustomUrl v-for="(el, elI) in urls" :key="elI" :index="elI" :info="el" inputType="url" />
									<small v-if="urlError" class="form__error">
										Per favore, inserisci un url valido
									</small>
								</template>
							</label>
						</div>
						<span class="edit__multi__add" @click="addUrl">
							<span class="edit__multi__add__icon"></span>
							<span class="edit__multi__add__label">
								Aggiungi Url
							</span>
						</span>
					</div>
				</form>
				<div class="edit__ctas bottom">
					<!-- <span class="edit__ctas__btn btn outline" @click="discardEdit">
						Indietro
					</span> -->
					<span class="edit__ctas__btn btn outline" @click="updateCard">
						Salva
					</span>
				</div>
			</template>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import CustomInput from '@/views/components/CustomInput';
import CustomPhone from '@/views/components/CustomPhone';
import CustomUrl from '@/views/components/CustomUrl';

export default {
	name: 'Edit',
	components: {
		CustomInput,
		CustomPhone,
		CustomUrl
	},
	data() {
		return {
			showContent: true,
			showCreate: false,
			owner: null,
			code: null,
			token: null,
			card: null,
			initial: {
				first: '',
				last: ''
			},
			emails: [],
			phones: [],
			urls: [],
			addresses: [],
			form: {
				claim: '',
				name: {
					family: '',
					given: '',
					additional: '',
					prefix: '',
					suffix: ''
				},
				org: '',
				title: '',
				role: '',
				birthday: '',
				address: [],
				email: [],
				phone: [],
				url: []
			},
			emailError: false,
			phoneError: false,
			urlError: false,
		}
	},
	methods: {
		emUpdate(event) {
			console.log(event);
		},
		addEmail() {
			this.emails.push({ default: false, type: 'work', value: '' });
			this.$forceUpdate();
		},
		addPhone() {
			this.phones.push({ default: false, type: '', value: '' });
			this.$forceUpdate();
		},
		addUrl() {
			this.urls.push({ default: false, label: '', type: 'work', value: '' });
			this.$forceUpdate();
			console.log('URLS', this.urls)
		},
		discardEdit() {
		},

		addCard() {
			this.form.claim = this.$route.query.t;

			this.form.email = [];
			this.form.email = [...this.emails];

			// this.form.phone = [];
			// this.form.phone = [...this.urls];
			//
			// this.form.url = [];
			// this.form.url = [...this.urls];

			console.log('FORM?', this.form);

			this.$service.vcard.update(this.$route.query.id, this.form).then(res => {
				const url = `/#/modifica?id=${this.$route.query.id}&t=${this.$route.query.t}&c=${new
				Date().getTime()}`;
				console.log('URL', url);

				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
		},
		updateCard() {
			this.form.claim = this.$route.query.t;

			this.form.email = [];
			this.form.email = [...this.emails];

			this.form.phone = [];
			this.form.phone = [...this.phones];

			this.form.url = [];
			this.form.url = [...this.urls];

			let errMail = 0;
			let errPhone = 0;
			let errUrl = 0;

			this.form.email.forEach(em => {
				if(em.value == '') {
					errMail += 1;
				}
			})
			this.form.phone.forEach(ph => {
				if(ph.value == '') {
					errPhone += 1;
				}
			})
			this.form.url.forEach(u => {
				if(u.value == '') {
					errUrl += 1;
				}
			})

			if(errMail > 0 || errPhone > 0 || errUrl > 0) {
				if(errMail > 0) {
					this.emailError = true;
				}
				if(errPhone > 0) {
					this.phoneError = true;
				}
				if(errUrl > 0) {
					this.urlError = true;
				}
			} else {
				this.$service.vcard.update(this.$route.query.id, this.form).then(res => {
					console.log('UPDATED', res);
					setTimeout(() => {
						window.location.href = `/#/?id=${this.$route.query.id}`;
					}, 500);
				})
			}

		},
		init() {
			if(this.$route && this.$route.query && this.$route.query.id && this.$route.query.t) {
				this.code = this.$route.query.id;
				this.token = this.$route.query.t;

				this.$service.login.verify(this.code, this.token).then(res => {
					if(res && res.owner) {
						if(this.form && this.form.email && !this.form.email.length) {
							this.emails.push({ default: true, type: 'work', value: res.owner });
						}
					}
					if(!localStorage.getItem('token')) {
						localStorage.setItem('token', this.token);
					}

					this.$service.vcard.get(this.code).then(res => {
						console.log('CARD', res, res.data);
						if(res && res.data !== null) {
							this.card = res;
							// this.showContent = true;
							setTimeout(() => {
								if(res.data) {
									this.showCreate = false;

									this.form.claim = this.$route.query.t;
									this.form.name.family = res.data.name.family;
									this.form.name.given = res.data.name.given;
									this.form.name.additional = res.data.name.additional;
									this.form.name.prefix = res.data.name.prefix;
									this.form.name.suffix = res.data.name.suffix;
									this.form.org = res.data.org;
									this.form.title = res.data.title;
									this.form.role = res.data.role;
									this.form.birthday = res.data.birthday;
									this.form.address = [...this.addresses];
									this.form.phone = [...this.phones];
									this.form.url = [...this.urls];

									if(res.data.email && res.data.email.length) {
										this.form.email = [...res.data.email];
										this.emails = [...res.data.email];
									}

									if(res.data.phone && res.data.phone.length) {
										this.form.phone = [...res.data.phone];
										this.phones = [...res.data.phone];
									}

									if(res.data.url && res.data.url.length) {
										this.form.url = [...res.data.url];
										this.urls = [...res.data.url];
									}

									console.log('LOG LOG LOG', this.form);
									setTimeout(() => {
										this.$forceUpdate();
									}, 100)

								} else {
									this.showCreate = true;
								}
							}, 100)
						} else {
							console.log('SHOW CREATE');
							this.showCreate = true;
						}
					})
				})
			}
		}
	},
	mounted() {
		this.init();

		EventBus.$on('remove-data', obj => {
			switch(obj.type) {
				case 'email':
					this.emails.splice(+obj.index, 1);
					break;
				case 'phone':
					this.phones.splice(+obj.index, 1);
					break;
				case 'url':
					this.urls.splice(+obj.index, 1);
					break;
			}
		})
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/edit';
</style>
